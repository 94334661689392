<template>
  <div class="tvgeemel_asuult_hariult">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="tvgeemel_asuult_hariult_title">
            <span>{{ 'common' | translate }}</span>{{ 'Q_and_A' | translate }}
          </div>
        </div>
      </div>
      <div v-if="loading" class="loading-wrap">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="answer_and_quistion">
        <div class="row">
          <div class="col-md-3">
            <ul class="no-list-style">
              <li :key="index" v-for="(category, index) in categorys">
                <a href="javascript:void(0);" class="item" :class="[(activeCategory == category.id) ? 'visible':'']" @click="callData(category.id)">
                  <span v-if="language.short == 'mn'">{{ category.asuul_torol }}</span>
                  <span v-else>{{ category.asuul_torol_en }}</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-9">
              <Collapse  accordion>
                  <Panel  :key="index" v-for="(answer_quistion, index) in answer_quistions" :value="index" >
                      <span v-html="answer_quistion.asuult_mn" v-if="language.short == 'mn'" ></span>
                      <span v-html="answer_quistion.asuult_em" v-else ></span>
                      <p slot="content" v-if="language.short == 'mn'" v-html="answer_quistion.hariult_mn"></p>
                      <p slot="content" v-else v-html="answer_quistion.hariult_en"></p>
                  </Panel>
              </Collapse>

<!--            <div class="accordion"  :key="index" v-for="(answer_quistion, index) in answer_quistions">-->
<!--              &lt;!&ndash;                      <div v-if="answer_quistion.question_category_id == 3">&ndash;&gt;-->
<!--              <a class="toggle "  :class="[(activeItem == answer_quistion.id) ? 'act-accordion':'']" @click="paymentActive(answer_quistion.id)" href="#" >-->
<!--                <div v-if="language.short == 'mn'" v-html="answer_quistion.asuult_mn" style="margin: 0!important;"> </div>-->
<!--                <div v-else v-html="answer_quistion.asuult_em" style="margin: 0!important;"> </div>-->
<!--                <span></span></a>-->
<!--              <div class="accordion-inner" :class="[(activeItem == answer_quistion.id) ? 'visible':'']">-->
<!--                <p v-if="language.short == 'mn'" v-html="answer_quistion.hariult_mn"></p>-->
<!--                <p v-else v-html="answer_quistion.hariult_en"></p>-->
<!--              </div>-->
<!--              &lt;!&ndash;                      </div>&ndash;&gt;-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {GET_FQA_ANGILAL_VIEW, GET_FQA_VIEW_BY_ID} from "../../graphql/queries";

export default {
  name: "tug_as_hariult",
  data () {
    return {
      value1: '1',
      loading: true,
      categorys: [],
      answer_quistions: [],
      activeItem: 0,
      activeCategory: 1,
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  mounted() {
    this.getGET_category();
    this.callData();
  },
  methods:{
    getGET_category(){
      this.loading = true;
      this.$apollo.query({ query: GET_FQA_ANGILAL_VIEW }).then(({data}) => {
        this.categorys = data.ds_fqa_angilal_view;
        this.loading = false;
      })
    },
    paymentActive(p) {this.activeItem = p;},
    callData(cat_id){
      this.currentCat = cat_id;
      this.activeCategory = cat_id;
      this.loading = true;
      if (this.currentCat) {
        this.$apollo.query({ query: GET_FQA_VIEW_BY_ID, variables: { cat_id: this.currentCat.toString() }}).then(({data}) => {
          this.answer_quistions = data.ds_fqa_view;
          setTimeout(() => {
            this.loading = false;
          })
        });
      }
      else{
        this.activeCategory = 1;
        this.$apollo.query({ query: GET_FQA_VIEW_BY_ID, variables: { cat_id: this.activeCategory.toString() }}).then(({data}) => {
          this.answer_quistions = data.ds_fqa_view;
          setTimeout(() => {
            this.loading = false;
          })
        });
      }
    },

  },
}

</script>

<style scoped>

</style>
