<template>
  <div class="help_page zuvulguu_medeelel">
     <div class="container">
       <div class="row">
         <div class="col-12">
          <div class="zuvulguu_medeelel_slide">
             <Tabs value="name1"   v-model="tab" class="">
               <!-- ТАНИЛЦУУЛГА TAB-->
                 <TabPane :label="'VIDEO' | translate" class=" " name="name2">
                     <help-videos></help-videos>
                 </TabPane>
               <TabPane :label= "'COMMON_QUESTIONS_AND_ANSWERS' | translate" class=" " name="name1">
                 <tug-as-hariult></tug-as-hariult>
               </TabPane>
             </Tabs>
          </div>
         </div>
       </div>
     </div>
  </div>
</template>

<script>

import TugAsHariult from "./help/tug_as_hariult";
import HelpVideos from "./help/videos";
import {mapGetters} from "vuex";

export default {
name: "tug_as_hariult",
  data () {
    return {
      value1: '1',
    }
  },
  components:{
     TugAsHariult,
     HelpVideos,
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  mounted() {

  },
  methods:{

  },
}
</script>

<style scoped></style>
