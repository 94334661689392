<template>
    <div class="tvgeemel_asuult_hariult">
        <div class="container">

            <div v-if="loading" class="loading-wrap">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>

            <div class="answer_and_quistion help_videos">
                <div class="row">
                    <div class="col-md-3">
                        <ul class="no-list-style">
                            <li :key="index" v-for="(category, index) in categorys">
                                <a href="javascript:void(0);" class="item"
                                   :class="[(activeCategory == category.id) ? 'visible':'']"
                                   @click="callData(category.id)">
                                    <span>{{ category.cat_video }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-9">
                        <swiper class="swiper" :options="medee_medeelel_slide">
                            <swiper-slide v-show="index <= 4" :key="index" v-for="(videos, index) in videos_p">
                                <div class="slide">
                                    <a class="toggle " :class="[(activeItem == videos.id) ? 'act-accordion':'']"
                                       href="#">
                                        <p class="help_video_title">{{ videos.title_mn }}</p>
                                        <div class=" video_slide">
                                            <div class="video_slide_item" v-if="videos.video">
                                                <youtube :video-id="getVideo(videos.video)" player-width="100%"
                                                         player-height="400"></youtube>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </swiper-slide>
                            <div class="swiper-pagination swiper_pag_down" slot="pagination"></div>
                        </swiper>
                        <div class="swiper-button-prev-1" slot="button-prev"><i
                                class="ivu-icon ivu-icon-ios-arrow-dropleft-circle"></i></div>
                        <div class="swiper-button-next-1" slot="button-next"><i
                                class="ivu-icon ivu-icon-ios-arrow-dropright-circle"></i></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_FQA_VIDEO_BY_ID, GET_FQA_VIDEOS_CATEGORY} from "@/graphql/queries";
import {getIdFromURL} from "vue-youtube-embed";

export default {
    name: "videos.vue",
    data() {
        return {
            getVideo: getIdFromURL,
            value1: '1',
            loading: true,
            categorys: [],
            videos_p: [],
            activeItem: 0,
            activeCategory: 1,
            medee_medeelel_slide: {
                slidesPerView: 1,
                spaceBetween: 15,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next-1',
                    prevEl: '.swiper-button-prev-1'
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 15
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 15
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    414: {
                        slidesPerView: 1,
                        spaceBetween: 15
                    },
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 15
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 15
                    }
                }
            },
        }
    },
    computed: {
        ...mapGetters([
            'languages',
            'language',
            // ...
        ])
    },
    mounted() {
        this.getGET_category();
        this.callData(1);
    },
    methods: {
        getGET_category() {
            this.loading = true;
            this.$apollo.query({query: GET_FQA_VIDEOS_CATEGORY}).then(({data}) => {
                this.categorys = data.lut_helper_video_cat;
                this.loading = false;
            })
        },
        callData(cat_id) {
            this.currentCat = cat_id;
            this.activeCategory = cat_id;
            this.loading = true;
            if (this.currentCat) {
                this.$apollo.query({
                    query: GET_FQA_VIDEO_BY_ID,
                    variables: {cat_id: cat_id.toString()}
                }).then(({data}) => {
                    this.videos_p = data.ds_helper_video;
                    setTimeout(() => {
                        this.loading = false;
                    })
                });
            } else {
                this.activeCategory = 1;
                this.$apollo.query({
                    query: GET_FQA_VIDEO_BY_ID,
                    variables: {cat_id: this.activeCategory.toString()}
                }).then(({data}) => {
                    this.videos_p = data.ds_helper_video;
                    setTimeout(() => {
                        this.loading = false;
                    })
                });
            }
        },
    },

}
</script>
<style scoped></style>